import styled from "styled-components";

const Content = styled.div`
    height: 120vh;
    background-image: url(${(props) => (props.bgImg ? props.bgImg : "")});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex: 1;
    @media screen and (max-width: 801px) {
        overflow: visible;
    }
`;
export default Content;
