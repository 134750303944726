/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useReducer } from "react";

interface ContextState {
    state: any;
    dispatch: any;
}

export default (reducer, actions, initialState) => {
    const Context = React.createContext({} as ContextState);
    const Provider: React.FC = ({ children }) => {
        const [state, dispatch] = useReducer(reducer, initialState);
        const boundActions = {};
        Object.keys(actions).forEach((key) => {
            boundActions[key] = actions[key](dispatch);
        });
        return (
            <Context.Provider value={{ state, dispatch: { ...boundActions } }}>
                {children}
            </Context.Provider>
        );
    };
    return { Context, Provider };
};
