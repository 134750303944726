import React, { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "../Theme";
import { Layout } from "antd";
import GlobalStyles from "../Global";
import { ContainerFluid } from "../Container";

interface Props {
    children: ReactNode;
}

const LayoutWrapper: React.FC<Props> = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <ContainerFluid>
                <GlobalStyles />
                <Layout>
                    <Layout>{children}</Layout>
                </Layout>
            </ContainerFluid>
        </ThemeProvider>
    );
};

export default LayoutWrapper;
