import styled from "styled-components";

export const Container = styled.div`
	margin-right: auto;
	margin-left: auto;
`;
export const ContainerFluid = styled(Container)`
	width: 100% !important;
	background: #f2f2f2;
	height: 100vh;
`;
