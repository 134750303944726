export const theme = {
	primary: "#0073AE",
	primaryLight: "#0E2A49",
	secondary: "#50BAD6",

	white: "#ffffff",
	fontDark: "#8897B1",
	fontDarkBold: "#58637E",
	errorColor: "#f5222d",
	linkTextColor: "#0A78B2",

	phoneXS: "401px",
	phone: "577px",
	tablet: "801px",
	desktop: "993px",
	large: "1201px",
	xtraLarge: "1441px",
	fullHd: "1920px",
	retina: "2560px",
};
