import React from "react";

import { Route } from "react-router-dom";
import Dashboard from "../../screens/Dashboard";
import { FormOutlined } from "@ant-design/icons";
import PageNotFound from "../../components/PageNotFound";

const BASE_URL = "/";

export const routes = [
    {
        name: "DASHBOARD",
        path: `${BASE_URL}dashboard`,
        exact: false,
        icon: <FormOutlined />,
        component: <Dashboard />,
        access: "*",
    },
    {
        name: "INVALID",
        path: "*",
        exact: false,
        component: <PageNotFound />,
        access: "invalid",
    },
];

export const getRoute = (permissions: boolean) => {
    return routes.map(({ name, path, exact, component }) => {
        const isAccessible = permissions;
        return (
            <Route
                key={name}
                exact={exact}
                path={path}
                render={() => (isAccessible ? component : <PageNotFound />)}
            />
        );
    });
};
