/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Form, FormItem, Input } from "formik-antd";
import { Alert } from "antd";
import { Wrapper, LoginButton, IagreeContainer, RecaptchaContainer } from "./styles";
import Logo from "../../assets/logo.svg";
import ReCAPTCHA from 'react-google-recaptcha'
import { ILoginFormProps } from './types';

let captcha: any

const LoginForm: React.FC<ILoginFormProps> = ({
    formikBag,
    errors,
    isAgreeChecked,
    setIsAgreeChecked,
    isRecaptchaChecked,
    setIsRecaptchaChecked,
    isShowErrorTermsAndCondition,
    isShowErrorRecaptcha,
    setRecaptcha
}) => {
    const { submitForm } = formikBag;
    
    return (
        <Form>
            <Wrapper>
                <div />
                <div className="login-box">
                    <img src={Logo} alt="brand-logo" />
                    <p className="header-title">MDDOR Portal</p>

                    <p className="help-text">
                        Where to find my credential?
                        <span>
                            <a> CLICK HERE</a>
                        </span>
                    </p>
                    {errors && <Alert message={errors} type="error" className="error-alert-box" />}

                    <FormItem name={"companyCode"}>
                        <Input name={"companyCode"} placeholder="Enter Company Code" />
                    </FormItem>
                    <FormItem name={"contractNo"}>
                        <Input name={"contractNo"} placeholder="Enter Contract Number" />
                    </FormItem>
                    <IagreeContainer>
                        <label htmlFor="i-agree">
                            <input
                                id="i-agree"
                                type="checkbox"
                                onChange={(): void => {
                                    setIsAgreeChecked(!isAgreeChecked)
                                }}
                                checked={isAgreeChecked}
                            />
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/role-has-required-aria-props */}
                            <span role="checkbox" />
                        </label>
                        We use cookies to improve your browsing experience. By continuing
                        to browse this site, you understood and agree to our{' '}
                        <a href="/privacy-notice" target="_blank">
                            Privacy Notice
                        </a>
                        {isShowErrorTermsAndCondition && !isAgreeChecked && (
                            <p>You must agree to the Terms and Conditions.</p>
                        )}
                    </IagreeContainer>
                    <RecaptchaContainer>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY || ''}
                            onChange={(value: string | null): void => {
                                setRecaptcha(value)
                                setIsRecaptchaChecked(!isRecaptchaChecked)
                            }}
                            /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type, no-return-assign */
                            ref={(e) => (captcha = e)}
                        />
                        {isShowErrorRecaptcha && !isRecaptchaChecked && (
                            <p>Please verify that you are not a robot.</p>
                        )}
                    </RecaptchaContainer>
                    <LoginButton block type="primary" onClick={() => {
                        submitForm()
                        captcha.reset()
                    }}>
                        Login
                    </LoginButton>
                    <p className="help-text">
                        {"Can't log in?"}
                        <span>
                            <a> Contact the Customer Service</a>
                        </span>
                    </p>
                </div>
                <div className="footer">
                    <p>
                        © {new Date().getFullYear()} Alveo Land <br />
                        DESIGNED BY WHITE CLOAK TECHNOLOGIES
                    </p>
                </div>
            </Wrapper>
        </Form>
    );
};
export default LoginForm;
