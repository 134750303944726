import React, { useState } from "react";
import { MdorViewer } from "./styles";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const Dashboard: React.FC = () => {
    const viewDocument:string = JSON.parse(localStorage.getItem("MDOR_FILE") || '');
    const mdorToken:string = JSON.parse(localStorage.getItem("MDOR_FILE_TOKEN") || '');
    const [numPages, setNumPages] = useState(0)
    const [page, setPage] = useState<number>(1)
    const [zoom, setZoom] = useState<number>(1)
    
    const mdorFile = {
        url: viewDocument,
        httpHeaders: {Authorization: `Bearer ${mdorToken}`}
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const zoomIn = () => {
        setZoom(zoom + 0.25)
    }

    const zoomOut = () => {
        setZoom(zoom - 0.25)
    }

    const changePage = (offset) => {
        setPage(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const PaginationComponent = () => {
        return (
            <div className="pager">
                <button
                    className="pager-btn"
                    type="button"
                    disabled={page <= 1}
                    onClick={previousPage}
                >
                    Prev
                </button>
                <p>
                    Page {page} of {numPages}
                </p>
                <button
                    className="pager-btn"
                    type="button"
                    disabled={page >= numPages}
                    onClick={nextPage}
                >
                    Next
                </button>
            </div>
        )
    }

    return (
        <MdorViewer>
            <Document
                file={mdorFile}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page
                    pageNumber={page}
                    scale={zoom}
                    renderTextLayer={false}
                />
                <button
                    className="zoom-btn zoom-btn--in"
                    onClick={zoomIn}
                >
                    &#x2b;
                </button>
                <button
                    className="zoom-btn zoom-btn--out"
                    onClick={zoomOut}
                    disabled={zoom === 1}
                >
                    &#x2013;
                </button>
                {numPages > 1 && <PaginationComponent />}
            </Document>
        </MdorViewer>
    );
};

export default Dashboard;
