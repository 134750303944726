import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
.modal-dialog {
  /* align-self: center;
  justify-content:'center';
  flex-direction:'column'; */

  max-width: 100%;
    margin: 0;   
    height: 100vh;
    display: flex;

}
 @font-face {
    font-family: 'proxima nova';
    src: url('../../../public/fonts/proxima-nova-alt-regular.ttf') format('truetype');
    font-style: normal;
  }
  
  body{
    overflow:inherit !important;
    font-family: ${(props) => props.theme.fontFamReg}, sans-serif !important;
   
  }
  
  .text-bold{
    font-weight:bold !important;
    input{font-weight:bold !important} 
  }
  .has-error{
    .custom-placeholder{
      color:red !important;
    }
  }
  .category-dropdown{
   .ant-dropdown-menu{
     padding:0 !important;
    }
    li{
      border-bottom:1px solid rgba(0,0,0,0.1);
    }
   
    .category-dropdown-item{

    display: flex;
    flex-direction: column;
    font-size: 1rem;
    padding: 5px;
    position: relative;
    .name,.description{
      
      white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
      width:200px;
    }
    .description{
      color:rgba(0,0,0,0.5);
      font-size: 12px;
    }
   
    .edit{
      position:absolute;
      top:0;
      right:0;
      font-size:12px;
      &:hover{
        color:blue;
      text-decoration:underline
      }
    }
    
  }
  }
  
`;
export default GlobalStyles;
