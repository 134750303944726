import styled from "styled-components";
import { Button } from "antd";
export const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    position: relative;
    .error-alert-box {
        background: #f0dada;
        border-radius: 4px;
        border-color: transparent;
        font-family: Helvetica;
        font-size: 12px;
        color: #ce6a6a;
        letter-spacing: 0;
        text-align: center;
        margin: 8px 0 8px 0;
    }
    .login-box {
        background: #ffffff;
        box-shadow: 0 22px 44px -20px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        padding: 65px;
        width: 500px;
        /* max-width: 700px; */
        @media screen and (max-width: 577px) {
            width: unset;
            padding: 30px;
        }
    }
    .header-title {
        font-family: Helvetica;
        font-size: 16px;
        color: #0073ae;
        text-align: center;
        line-height: 35px;
        margin-bottom: 30px;
    }
    .help-text {
        font-family: Helvetica;
        font-size: 12px;
        color: #9b9b9b;
        letter-spacing: 0.5px;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
        a {
            color: ${(props) => props.theme.primary};
        }
    }
    .footer {
        padding-top: 10px;
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 1px;
        text-align: center;
        line-height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        left: 0;
    }
`;

export const LoginButton = styled(Button)`
    height: 40px;
    background: #0073ae;
    box-shadow: 0 1px 4px 0 rgba(47, 114, 169, 0.5);
    border-radius: 4px;
    /* font-family: "ProximaNovaA-Bold"; */
    font-size: 13px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
    &:hover {
        background: #0073ae;
        box-shadow: 0 1px 4px 0 rgba(47, 114, 169, 0.5);
        color: #ffffff;
    }
`;

export const IagreeContainer = styled.div`
    label {
      margin-left: -4px;
      margin-right: 4px;
    }

    a {
      color: #0473ae;
    }

    p {
      width: fit-content;
      margin: 0 auto;
      color: red;
      margin-top: 10px;
    }

    margin-top: 15px;
    font-size: 12px;
`;

export const RecaptchaContainer = styled.div`
    display: block;
    margin: 15px auto;
    width: 302px;

    p {
      width: fit-content;
      margin: 0 auto;
      color: red;
      margin-top: 10px;
      font-size: 12px;
    }
`;
