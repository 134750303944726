import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
    mutation mdorLogin($data: EmployeeMdorLoginInput!) {
        mdorEmployeeLogin(employeeMdorLoginInput: $data) {
            token
            document {
                mdor_document {
                    document_custom_id
                    file
                }
            }            
        }
    }
`;
