import React, { useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";

import "./App.less";
import MainLayout from "./components/Layout";
import { getRoute } from "./context/routes";

const App: React.FC = () => {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const isAuthenticated = !!token;
    const [userPermissions, setUserPermissions] = useState<boolean>(false);
    useEffect(() => {
        if (isAuthenticated) {
            setUserPermissions(true);
        }
    }, []);
    return (
        <MainLayout data-index="layout">
            <Switch>
                {isAuthenticated ? getRoute(userPermissions) : <Redirect to="/login" />}
            </Switch>
        </MainLayout>
    );
};

export default App;
