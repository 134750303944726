import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Login from "./screens/Login";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "./context";
import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    NormalizedCacheObject,
    InMemoryCache,
    ApolloLink,
    concat,
} from "@apollo/client";
const { REACT_APP_CONTENT_URL } = process.env;

const cache = new InMemoryCache();

const httpLink = new HttpLink({ uri: `${REACT_APP_CONTENT_URL}/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}` || null,
        },
    });
    return forward(operation);
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link: concat(authMiddleware, httpLink),
});
ReactDOM.render(
    <React.StrictMode>
        <head>
            <title>Alveo MDOR</title>
            <meta property="og:title" content="Alveo MDOR" />
        </head>
        <ApolloProvider client={client}>
            <Provider>
                <Router basename={`${process.env.PUBLIC_URL}`}>
                    <Switch>
                        <Route exact path={["/", "/login"]} render={() => <Login />} />
                        <Route path={["/:page/:subPage", "/:page"]} render={() => <App />} />
                    </Switch>
                </Router>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
